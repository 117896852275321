<template>
    <div style="width:1200px;margin:0 auto;padding-top:20px">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home/HomePage' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/Mall' }">商品超市</el-breadcrumb-item>
            <el-breadcrumb-item>我的采购单</el-breadcrumb-item>
        </el-breadcrumb>

        <div style="margin-top:20px;background:#fff;height:400px;position:relative">
            <div style="position:absolute;left:40%;top:30%;text-align:center">
                <img src="@/assets/result-1.png" alt="" >
                <p style="margin:20px 0 10px">你需要开通"认证会员"才能下订单</p>
                <p>立即<span style="color:#38A3C5;margin-left:5px">申请认证会员</span></p>
            </div>

        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style>

</style>
