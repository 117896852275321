var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { width: "1200px", margin: "0 auto", "padding-top": "20px" },
    },
    [
      _c(
        "el-breadcrumb",
        { attrs: { separator: "/" } },
        [
          _c(
            "el-breadcrumb-item",
            { attrs: { to: { path: "/home/HomePage" } } },
            [_vm._v("首页")]
          ),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/home/Mall" } } }, [
            _vm._v("商品超市"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("我的采购单")]),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          "margin-top": "20px",
          background: "#fff",
          height: "400px",
          position: "relative",
        },
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              position: "absolute",
              left: "40%",
              top: "30%",
              "text-align": "center",
            },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/result-1.png"), alt: "" },
            }),
            _c("p", { staticStyle: { margin: "20px 0 10px" } }, [
              _vm._v('你需要开通"认证会员"才能下订单'),
            ]),
            _c("p", [
              _vm._v("立即"),
              _c(
                "span",
                { staticStyle: { color: "#38A3C5", "margin-left": "5px" } },
                [_vm._v("申请认证会员")]
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }